import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InstagramIcon from '@material-ui/icons/Instagram';
import Container from '@material-ui/core/Container';
import FacebookIcon from '@material-ui/icons/Facebook';

const useStyles = makeStyles((theme) => ({


  root: {
    flexGrow: 1,
    
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  logo: {
    display: 'inline-flex',
    verticalAlign: 'Top',
    height: '30px',
    paddingRight: '7px'
  }
}));

export default function TopoAppBar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="static" style={{backgroundColor: "#00A0cc"}}>
        <Container maxWidth="lg">
          <Toolbar disableGutters={true} >
            <Typography variant="h6" className={classes.title}>
              <img alt="logotime" className={classes.logo} src="../escudo.png" />
            Escolinha Estrelas de Ouro
          </Typography>


            <Typography variant="body2" align="right">
              <Link href="https://www.instagram.com/escolinhaestrelasdeouro/">
                <InstagramIcon style={{ color: 'white', fontSize: '30px' }} />
              </Link>

              </Typography>
              <Typography variant="body2" align="right">
              <Link href="https://www.facebook.com/Escolinha-Estrelas-de-Ouro-104021641477930/">
                <FacebookIcon style={{ color: 'white', fontSize: '30px' }} />
              </Link>
            </Typography>

          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}