import React from 'react';
import Container from '@material-ui/core/Container';

var style = {
    backgroundColor: "#888888",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    paddingTop: "10px",
    paddingBottom: "10px",
    marginTop: "30px"
}


function Rodape({ children }) {
    return (
        <div style={style}>
            <Container maxWidth="lg">
                    { children }
            </Container>
        </div>
    )
}

export default Rodape