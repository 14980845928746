import { Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import React from "react";
import Typography from "@material-ui/core/Typography";
import "../../src/index.css";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import ReactPlayer from "react-player";
import "./home.css";
import { Link } from "@material-ui/core";



const useStyles = (theme) => ({
  root: {
    display: "flex",
    flexwrap: "wrap",
    "& > *": {
      margin: theme.spacing(10),
      width: theme.spacing(50),
      height: theme.spacing(20),
    },
  },
});

class Home extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div>
        <Container
          maxWidth="lg"
          style={{ textAlign: "center", padding: "30px" }}>

          <Typography variant="h5" >
                <b> Não perca sua chance </b> de fazer parte desse<b> incrível lançamento </b> em Chapadinha.<br/>
          </Typography>

          <Typography variant="h6">
                <i><b>Escolinha de Esporte Estrelas de Ouro, </b> um clube com a cara da sua família.</i> <br/> <br/>
                <b> Reservas:</b>
                <Link href="https://api.whatsapp.com/send?phone=5598984520674" className={classes.textoRodape}>
                  <b style={{ color: "#0822b3" }}>(098) 98452-0674 </b>
                 </Link>
                / 3471-3063
          </Typography>
         
        </Container>

        <div style={{ backgroundColor: "black" }}>
          <Container
            maxWidth="lg"
            className="blocoVideo"
            style={{ textAlign: "center", backgroundColor: "black" }}
          >
            <Box display="flex" justifyContent="center">
              <ReactPlayer
                width="100%"
                height="100%"
                style={{ maxWidth: "800px" }}
                controls={true}
                url="https://jldantas.s3.amazonaws.com/video/video_v3.mp4"
              />
            </Box>
          </Container>
        </div>
        <Container
          maxWidth="lg"
          style={{ textAlign: "center", padding: "30px" }}
        >
          <Typography variant="h6" style={{ color: "blue" }}>
            Lançamento da pedra fundamental em 12/10 com{" "}
            <b>Entrega de Presentes para as crianças</b>
          </Typography>
        </Container>

        <Container>
          
        
        </Container>
                
      </div>

    );
  }
}

export default withStyles(useStyles)(Home);
