import React from "react";
import Rodape from "./Rodape";
import { Link } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MailIcon from "@material-ui/icons/Mail";
import PhoneIcon from "@material-ui/icons/Phone";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import Grid from '@material-ui/core/Grid';
import './rodape.css';

const useStyles = (theme) => ({
  root: {
    display: "inline-flex",
    verticalAlign: "top",
    fontSize: "17px",
    paddingRight: "3px"
  },
  textoRodape: {
      color: "white"
  }
});

class RodapeConteudo extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Rodape>
        <Grid container >
          <Grid item xs={12} sm={4}>
            <Typography variant="body2" className='rodapePrimeiroItem' >
              <Link href="mailto:escolinha.estrelas.de.ouro@gmail.com" className={classes.textoRodape}>
                <MailIcon className={classes.root} />
                <span>Entre em contato</span>
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body2">
              <Link href="tel:098-3471-3063" className={classes.textoRodape}>
                <PhoneIcon className={classes.root} /> <span>98 3471-3063</span>
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography variant="body2"  className='rodapeUltimoItem'>
              <Link href="https://api.whatsapp.com/send?phone=5598984520674" className={classes.textoRodape}>
                <WhatsAppIcon className={classes.root} />
                <span>98 98452-0674</span>
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Rodape>
    );
  }
}

export default withStyles(useStyles)(RodapeConteudo);
